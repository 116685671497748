import { Cluster } from 'ui/components/3-organisms/Cluster/Cluster';

export const ClusterFeature: React.FC<Content.Cluster> = ({ content }) => {
	const { color, items } = content?.properties ?? {};
	return (
		<Cluster
			{...content?.properties}
			bgColor={color}
			items={items?.map((item) => ({
				url: item?.url,
				focalPoint: item?.focalPoint,
				altText: item?.properties.altText,
			}))}
		/>
	);
};
