import classNames from 'classnames';
import { Picture } from 'ui/components/1-atoms/Media/Picture/Picture';
import styles from './Cluster.module.scss';
import ColorOptions from 'helpers/types';
import { useInView } from 'react-intersection-observer';

export interface ClusterProps {
	className?: string;
	bgColor?: ColorOptions;
	items?: Array<{
		url?: string;
		altText?: string;
		focalPoint?: { left: number; top: number };
	}>;
}

export const Cluster: React.FC<ClusterProps> = ({ className, items, bgColor = 'white' }) => {
	// API options available here: https://www.npmjs.com/package/react-intersection-observer
	const { ref, inView } = useInView({
		threshold: 0.5,
		triggerOnce: true,
	});
	return (
		<article ref={ref} className={classNames(styles.Cluster, { isInView: inView }, className)}>
			<div className={classNames(styles.Cluster_bgElement, styles[`u-color___${bgColor}`])}></div>
			{items && (
				<div className={styles.Cluster_inner}>
					{items.map((item, index) => (
						<Picture
							key={index}
							focalPoint={item?.focalPoint}
							url={item?.url}
							altText={item?.altText}
							sizes="(max-width: 768px) 30vw, 25vw"
							className={styles.Cluster_imageWrapper}
							imageCropping="cover"
						/>
					))}
				</div>
			)}
		</article>
	);
};
